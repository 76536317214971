<template>
  <div>
    <component :is="tag">{{ displayNumber }}</component>
  </div>
</template>
<script>
export default {
  name: "AnimateNumber",
  data() {
    return {
      displayNumber: 0,
      interval: false,
    };
  },
  props: {
    number: {
      type: Number,
      default: 0,
      required: true,
    },
    tag: {
      type: String,
      default: "span",
    },
  },
  watch: {
    number() {
      clearInterval(this.interval);

      if (this.number == this.displayNumber) {
        return;
      }

      this.interval = window.setInterval(() => {
        if (this.displayNumber != this.number) {
          var change = (this.number - this.displayNumber) / 10;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayNumber = this.displayNumber + change;
        }
      }, 20);
    },
  },
  created() {
    this.displayNumber = this.number ? this.number : 0;
  },
};
</script>
