<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-7">
        <h3 class="text-uppercase">Users</h3>
      </div>
      <div class="col-12 col-md-5 d-flex">
        <el-select
          v-model="filter.isVerified"
          @change="GetAll()"
          class="me-2"
          style="width: 200px"
        >
          <el-option
            v-for="status in UserStatus"
            :key="status.value"
            :label="status.text"
            :value="status.value"
          ></el-option>
        </el-select>
        <el-input
          v-model="filter.filterText"
          placeholder="search here..."
          @keyup="GetAll()"
          clearable
          class="me-2"
        >
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <el-tooltip content="Add User">
          <el-button @click="resetUser(), (editDialog = true)" type="primary">
            <i class="bi bi-plus-circle-fill"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip content="Activate Multiple">
          <el-button @click="ActivateUser('multi')" type="primary">
            <i class="bi bi-check-circle-fill"></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <el-divider class="my-1"></el-divider>
    <div class="row justify-content-between py-2">
      <div class="col-12 col-md-3 mb-2 mb-md-0">
        <div class="card py-3">
          <div class="card-body">
            <div
              class="text-center d-flex flex-column justify-content-center align-items-center"
            >
              <div class="d-flex">
                <span>Total Users</span>
                <i class="ms-2 text-primary bi bi-people"></i>
              </div>
             
              <animate-number
                tag="h1"
                :number="stats.totalUsers"
              ></animate-number>
             
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-2 mb-md-0">
        <div class="card py-3">
          <div class="card-body">
            <div
              class="text-center d-flex flex-column justify-content-center align-items-center"
            >
            <div class="d-flex">
                <span>Active Users</span>
                <i class="ms-2 text-success bi bi-person-check"></i>
              </div>
              <animate-number
                tag="h1"
                :number="stats.activeUsers"
              ></animate-number>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="card py-3">
          <div class="card-body">
            <div
              class="text-center d-flex flex-column justify-content-center align-items-center"
            >
            <div class="d-flex">
                <span>Inactive Users</span>
                <i class="ms-2 text-warning bi bi-person-slash"></i>
              </div>
              <animate-number
                tag="h1"
                :number="stats.inactiveUsers"
              ></animate-number>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-table
      :data="Users"
      :fit="true"
      max-height="80vh"
      class="cstm-table"
      header-cell-class-name="tbl-header"
      size="small"
      stripe
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="100" class="py-1">
          <template #description>
            <span class="my-0">No Data Found</span>
          </template>
        </el-empty>
      </template>
      <template #append>
        <div class="d-flex justify-content-center py-2 px-1">
          <el-pagination
            v-if="Users.length > 0"
            v-model:current-page="pagination.page"
            v-model:page-size="pagination.pageSize"
            :page-sizes="[25, 50, 100]"
            :total="pagination.records"
            background
            layout="prev, pager, next, ,sizes"
            small
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </template>
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column label="Name" prop="fullName"></el-table-column>
      <el-table-column label="Email" prop="username"></el-table-column>
      <el-table-column label="Password" prop="password"></el-table-column>
      <el-table-column label="Action" width="100" align="center" fixed="right">
        <template #default="scope">
          <el-space class="mt-1">
            <el-tooltip content="Edit" v-if="scope.row.accountVerified">
              <el-icon
                color="green"
                @click="takeData(scope.row, 'edit')"
                :disabled="selectedIds.length > 0"
              >
                <i class="bi bi-person-gear action-icon fs-5"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Revoke" v-if="scope.row.accountVerified">
              <el-icon
                color="orange"
                @click="takeData(scope.row, 'revoke')"
                :disabled="selectedIds.length > 0"
              >
                <i class="mgc_alert_octagon_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Activate" v-else>
              <el-icon
                color="green"
                @click="takeData(scope.row, 'activate')"
                :disabled="selectedIds.length > 0"
              >
                <i class="mgc_check_circle_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Delete">
              <el-icon
                color="red"
                @click="takeData(scope.row, 'del')"
                :disabled="selectedIds.length > 0"
              >
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <!--    Dialogs-->
    <el-dialog
      v-model="editDialog"
      draggable
      align-center
      :title="User.userId > 0 ? 'Update User' : 'Add User'"
      width="450"
      :center="false"
    >
      <template #default>
        <el-form label-position="top" :model="User" hide-required-asterisk>
          <el-form-item prop="fullName">
            <template #label>
              <span class="required">Full Name</span>
            </template>
            <el-input v-model="User.fullName"></el-input>
          </el-form-item>
          <el-form-item prop="username" :rules="formRules.username">
            <template #label>
              <span class="required">Username</span>
            </template>
            <el-input v-model="User.username"></el-input>
          </el-form-item>
          <el-form-item prop="password" :rules="formRules.password">
            <template #label>
              <span class="required">Password</span>
            </template>
            <el-input
              v-model="User.password"
              show-password
              @keydown.space.prevent
            ></el-input>
          </el-form-item>
          <el-form-item prop="accountVerified">
            <template #label>
              <span class="">Account Status</span>
            </template>
            <el-select v-model="User.accountVerified">
              <el-option
                v-for="status in UserStatus"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="role">
            <template #label>
              <span class="">Role</span>
            </template>
            <el-select v-model="User.role">
              <el-option
                v-for="role in Roles"
                :key="role.value"
                :label="role.text"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="editDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            class="ms-2"
            @click="
              (editDialog = false), User.userId > 0 ? UpdateUser() : AddUser()
            "
          >
            {{ User.userId > 0 ? "Update User" : "Add User" }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" title="Delete User" width="450">
      <template #default>
        <span
          >Are you sure? You want to delete
          <span class="mfw-bold text-danger">{{ User.fullName }}</span
          >.</span
        >
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button
            type="danger"
            class="ms-2"
            @click="(deleteDialog = false), RemoveUser()"
            >Delete</el-button
          >
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="revokeDialog" title="Revoke User" width="450">
      <template #default>
        <span
          >Are you sure? You want to revoke
          <span class="mfw-bold text-warning">{{ User.fullName }}</span
          >.</span
        >
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="revokeDialog = false">Cancel</el-button>
          <button
            class="btn btn-sm btn-warning ms-2"
            @click="(revokeDialog = false), RevokeUser()"
          >
            Revoke
          </button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="activateDialog" title="Activate User" width="450">
      <template #default>
        <span
          >Are you sure? You want to activate
          <span class="mfw-bold text-success">{{ User.fullName }}</span
          >.</span
        >
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="activateDialog = false">Cancel</el-button>
          <button
            class="btn btn-sm btn-success ms-2"
            @click="(activateDialog = false), ActivateUser()"
          >
            Activate
          </button>
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import userService from "@/Services/userService";
import { ShowMessage } from "@/Utility/Utility";
import AnimateNumber from "../Utility/AnimateNumber.vue";
export default {
  name: "UsersList",
  components: { AnimateNumber },
  data() {
    return {
      loading: false,
      editDialog: false,
      revokeDialog: false,
      deleteDialog: false,
      activateDialog: false,
      selectedIds: [],
      currentUser: {},
      Users: [],
      stats: {
        activeUsers: 0,
        inactiveUsers: 0,
        totalUsers: 0,
      },
      UserStatus: [
        { text: "Active", value: true },
        { text: "InActive", value: false },
      ],
      Roles: [
        { text: "Admin", value: 20 },
        { text: "User", value: 30 },
      ],
      User: {
        userId: 0,
        username: "",
        fullName: "",
        password: "",
        accountVerified: true,
        subscriptionCode: "",
        validationDate: new Date(),
        expiryDate: new Date(),
        role: 20,
      },
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        isVerified: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 30,
      },
      formRules: {
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
          // You can add more rules for the name field if needed
        ],
        username: [
          {
            required: true,
            message: "Please enter the username",
            trigger: "blur",
          },
          //{type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          //{validator: this.validateUsername, trigger: 'blur'}
        ],
      },
      pagination: {},
    };
  },
  methods: {
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.GetAll();
      this.getStats();
    },
    handleCurrentChange(newPage) {
      this.filter.page = newPage;
      this.GetAll();
      this.getStats();
    },
    handleSelectionChange(users) {
      this.selectedIds = users.map((m) => m.userId);
    },
    takeData(user, type) {
      this.User = { ...user };
      if (type === "del") {
        this.selectedIds.push(this.User.userId);
        this.deleteDialog = true;
      }
      if (type === "edit") {
        this.editDialog = true;
      }
      if (type === "revoke") {
        this.revokeDialog = true;
      }
      if (type === "activate") {
        this.selectedIds.push(this.User.userId);
        this.activateDialog = true;
      }
    },
    async AddUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.add(this.User);
        ShowMessage("success", "User added");
        this.GetAll();
        this.getStats();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async UpdateUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.updateProfile(this.User);
        ShowMessage("success", "User updated");
        this.GetAll();
        this.getStats();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async GetAll() {
      try {
        this.loading = true;
        let response = await userService.getAll(this.filter);
        this.Users = response.data.data ?? [];
        this.pagination = response.data.pagination;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async RemoveUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.remove(this.selectedIds);
        ShowMessage("success", "User deleted");
        this.loading = false;
        this.GetAll();
        this.getStats();
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.");
      }
    },
    async RevokeUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.revoke(this.User.userId);
        ShowMessage("success", "User revoked");
        this.GetAll();
        this.getStats();
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.");
      }
    },
    async ActivateUser(type) {
      try {
        this.loading = true;
        if (type == "multi") {
          if (this.selectedIds.length < 1) {
            ShowMessage("warning", "No user selected");
            this.loading = false;
            return;
          }
        }
        // eslint-disable-next-line no-unused-vars
        let response = await userService.activate(this.selectedIds);
        if (this.selectedIds.length > 1) {
          ShowMessage("success", "User activated");
        } else {
          ShowMessage("success", "Users activated");
        }
        this.GetAll();
        this.getStats();
        this.selectedIds = [];
        this.resetUser();
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.");
      }
    },
    async getStats() {
      this.loading = true;
      try {
        let response = await userService.getStats(this.filter);
        this.stats = response.data?.data;
      } catch (e) {
        ShowMessage(
          "error",
          e?.response?.data?.message ?? "Fetching data failed"
        );
      }
    },
    resetUser() {
      this.User = {
        userId: 0,
        username: "",
        fullName: "",
        password: "",
        accountVerified: true,
        subscriptionCode: "",
        validationDate: new Date(),
        expiryDate: new Date(),
        role: 20,
      };
    },
  },
  created() {
    this.GetAll();
    this.getStats();
  },
};
</script>
<style scoped></style>
