<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Add Domain</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <router-link to="add-domain">
          <el-button text bg class="me-2">
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <!--        <el-button v-if="Domain.id < 1" type="primary" @click="AddDomain()">Add Domain</el-button>
                <el-button v-else type="primary" @click="AddDomain()">UpdateDomain</el-button>-->
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="col-12 col-md-8 col-xl-6 bg-body-secondary p-3 rounded-1">
      <el-form hide-required-asterisk label-position="top" :model="Domain">
        <el-form-item prop="domainName" :rules="formRules.domainName">
          <template #label></template>
          <span class="required">Domain Name</span>
          <el-input v-model="Domain.domainName" placeholder="Domain name here...">
            <template #prepend>
              <span>https://</span>
            </template>
          </el-input>
        </el-form-item>
        <div class="card p-2 py-4 border-0">
          <span class="text-caption">Is any content currently published on this domain?</span>
          <el-radio-group v-model="contentPublished">
            <el-radio :label="false" @click="UseMainDomain()">
              <span class="text-caption">No, this is unused domain</span>
            </el-radio>
            <el-radio :label="true">
              <span class="text-caption">Yes, I use it for my blog, website, social media, work, etc...</span>
            </el-radio>
          </el-radio-group>
          <div v-if="!contentPublished" class="card p-2 bg-danger-subtle border-0 mt-3">
            <span class="text-caption"><b>Warning: </b> A domain cannot point to two places at the same time. If you connect this domain to brand your Magiclinkz links, the domain will no longer be associated with your current website. Any existing content or data will be inaccessible.</span>
            <el-checkbox v-model="confirmUnused" @change="Confirm(confirmUnused)">
              <span class="text-caption">I confirm, I do not have any critical data or content on this domain</span>
            </el-checkbox>
          </div>
          <div v-else class="card p-2 bg-danger-subtle border-0 mt-3">
            <span class="text-caption"><b>Warning: </b>Please be careful not to configure a domain that has some content already posted or that is important for the operation of your site (for example www, webmail, etc.). If you connect it, any existing content will be inaccessible.
If you want to connect this domain name and not lose access to your site, you will have to use a subdomain.</span>
            <el-checkbox v-model="confirmUnused" @change="Confirm(confirmUnused)">
              <span class="text-caption">I confirm, I do not have any critical data or content on this domain</span>
            </el-checkbox>
            <el-button plain type="warning" class="w-25" @click="useSubDomain = !useSubDomain,UseSubDomain()">
              Use SubDomain
            </el-button>
          </div>
          <div class="mt-2" v-if="useSubDomain">
            <span class="text-caption required">Enter Subdomain</span>
            <el-input v-model="Domain.subDomain">
              <template #prepend>
                <span>https://</span>
              </template>
              <template #append>
                <span>{{ Domain.domainName }}</span>
              </template>
            </el-input>
          </div>
        </div>
        <div class="mt-3">
          <router-link to="">
            <el-button @click="AddDomain" :disabled="!confirmUnused || !Domain.domainName" type="primary">Next
            </el-button>
          </router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddDomain",
  data() {
    return {
      loading: true,
      useSubDomain: false,
      contentPublished: false,
      confirmUnused: false,
      formRules: {
        domainName: [
          {required: true, message: 'Domain name not valid', trigger: 'blur'},
        ],

      },
      Domain: {
        "userId": sessionStorage.getItem("userId"),
        "id": 0,
        "domainName": "",
        "subDomain": "",
        "status": 20,
        "type": 5,
        "landingUrl": "",
        "errorUrl": "",
      },
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 30,
      },
    }
  },
  methods: {
    Confirm(value) {
      this.confirmUnused = value;
      if (this.contentPublished) {
        this.$store.state.isDomain = false;
      } else {
        this.$store.state.isDomain = true
      }
    },
    UseSubDomain() {
      this.$store.state.isDomain = false
    },
    UseMainDomain() {

      this.$store.state.isDomain = true
    },
    AddDomain() {
      this.$store.state.Domain = this.Domain;
      this.$router.push('/instructions');
    }
  }
}
</script>

<style scoped>

</style>