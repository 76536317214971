<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-9">
        <h3>Dashboard</h3>
      </div>
      <div class="col-12 col-md-3">
        <el-select v-model="filter.days" @change="GetDate()">
          <el-option v-for="time in timeFrames" :key="time.value" :label="time.text" :value="time.value"></el-option>
        </el-select>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="col-12">
      <el-card shadow="never">
        <ClicksChart :data="VisitsData"></ClicksChart>

      </el-card>
    </div>
    <div class="mt-2 d-flex">
      <div class="col-12 col-md-6  pe-md-1 custom-equal-height">
        <el-card shadow="never">
          <h5 class="fw-bold text-black">Clicks By Platforms <i class="bi bi-pc-display-horizontal text-success"></i></h5>
          <el-empty :image-size="80" v-if="platformData.length < 1" description="No Platforms Data Found" />
          <PlatformChart v-else :data="platformData"></PlatformChart>
        </el-card>
      </div>
      <div class="col-12 col-md-6 ps-md-1 custom-equal-height">
        <el-card shadow="never">
          <h5 class="fw-bold text-black">Top Browsers <i class="bi-browser-chrome text-warning"></i></h5>
          <el-empty :image-size="80" v-if="browserData.length < 1" description="No Browsers Data Found" />
          <BrowserChart v-else :data="browserData"></BrowserChart>
        </el-card>
      </div>
    </div>
    <div class="mt-2 d-flex">
      <div class="col-12 col-md-6  pe-md-1 custom-equal-height">
        <el-card shadow="never">
          <h5 class="fw-bold text-black">Top Tracking urls <i class="mgc_link_2_line text-primary"></i></h5>
          <el-empty :image-size="80" v-if="topTrackUrls.length < 1" description="No Top Tracking Urls Found" />
          <el-table v-else :data="topTrackUrls" :fit="true" max-height="80vh"
                    class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
          >
          <el-table-column type="index" width="30" label="#" align="center"></el-table-column>
          <el-table-column label="Link" prop="redirectionLink"></el-table-column>
          <el-table-column label="Visits" prop="visits" width="55" fixed="right" class-name="text-center"></el-table-column>
          </el-table>
        </el-card>
      </div>
      <div class="col-12 col-md-6 ps-md-1 custom-equal-height">
        <el-card shadow="never">
          <h5 class="fw-bold text-black">Top Broken urls <i class="mgc_scissors_3_line text-danger"></i></h5>
          <el-empty :image-size="80" v-if="brokenUrls.length < 1" description="No Broken Urls Found" />
          <el-table v-else :data="brokenUrls" :fit="true" max-height="80vh"
                    class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
          >
            <el-table-column type="index" width="30" label="#" align="center"></el-table-column>
            <el-table-column label="Link" prop="redirectionLink"></el-table-column>
            <el-table-column label="Visits" prop="visits" width="55" fixed="right" class-name="text-center"></el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <div v-if="loading" class="spinner-border d-loader"></div>

  </div>
</template>
<script>


import dashboardService from "@/Services/dashboardService";
import BrowserChart from "@/components/Dashboard/Charts/BrowsersChart.vue";
import PlatformChart from "@/components/Dashboard/Charts/PlatformsChart.vue";
import ClicksChart from "@/components/Dashboard/Charts/ClicksChart.vue"
export default {
  name: "DashboardComp",
  components: {PlatformChart, BrowserChart, ClicksChart},
  data() {
    return {
      loading: false,

      VisitsData: [],
      browserData: [],
      platformData: [],
      topTrackUrls: [],
      brokenUrls: [],
      data: [],
      timeFrames: [
        {text: "Last 7 Days", value: 7},
        {text: "Last 30 Days", value: 30},
        {text: "Last 90 Days", value: 90},
        {text: "Show All", value: 200},
      ],
      filter: {
        "days": 30,
        "fromDate": new Date(),
        "toDate": new Date(),
        "userId": parseInt(sessionStorage.getItem("userId")),
      },
    }
  },
  methods: {
    GetDate() {
      this.filter.fromDate = new Date(this.filter.toDate);
      this.filter.fromDate.setDate(this.filter.toDate.getDate() - this.filter.days);
      this.filter.toDate = new Date();
      this.GetData();
    },
    async GetData() {
      try {
        this.loading = true;
        let response = await dashboardService.getAll(this.filter);
        this.VisitsData = response.data.data.visitsData;
        this.browserData = response.data.data.topBrowsers;
        this.platformData = response.data.data.topPlatformData;
        this.topTrackUrls = response.data.data.topTrackingUrls;
        this.brokenUrls = response.data.data.topBrokenUrls;

        this.loading = false;
      } catch {
        this.loading = false;
      }
    }
  },
  created() {
    this.GetDate();
  }
}
</script>
<style scoped>
.custom-equal-height {
  display: flex;
  flex-direction: column;
}

.custom-equal-height > * {
  flex: 1;
}
</style>