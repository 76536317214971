import axios from "axios";
import Config from "@/Configuration/Config";

export default {
    authenticateUrl: Config.apiBaseUrl + "api/Auth/Authenticate",
    getUserUrl: Config.apiBaseUrl + "api/User/Get",
    updateUserUrl: Config.apiBaseUrl + "api/User/Update",
    allUrl: Config.apiBaseUrl + "api/User/All",
    removeUrl: Config.apiBaseUrl + "api/User/DeleteUser",
    revokeUrl: Config.apiBaseUrl + "api/User/RevokeUser",
    activateUrl: Config.apiBaseUrl + "api/User/ActivateUser",
    addUserUrl: Config.apiBaseUrl + "api/Auth/AddUser",
    validateSubscriptionUrl: Config.apiBaseUrl + "api/Subscription/Validate",
    forgotPasswordUrl: Config.apiBaseUrl + "api/User/ForgotPassword",
    signupUrl: Config.apiBaseUrl + "api/Auth/Signup",
    getStatsUrl: Config.apiBaseUrl + "api/User/GetStats",


    getAll(filter) {
        return axios.post(this.allUrl, filter)
    },
    login(user) {
        return axios.post(this.authenticateUrl, user)
    },
    getUser() {
        return axios.get(this.getUserUrl)
    },
    updateProfile(user) {
        return axios.post(this.updateUserUrl, user);
    },
    add(user) {
        return axios.post(this.addUserUrl, user);
    },
    remove(ids) {
        return axios.post(this.removeUrl,ids);
    },
    signup(user) {
        return axios.post(this.signupUrl,user);
    },
    resetPassword(email) {
        const fd = new FormData();
        fd.append("email", email);
        return axios({
            method: "post",
            url: this.forgotPasswordUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
        });
    },
    revoke(id) {
        const fd = new FormData();
        fd.append("userId", id)
        return axios({
            method: "post",
            url: this.revokeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    },
    activate(ids) {
       return axios.post(this.activateUrl, ids)
    },
    validateSubscription(subscription) {
       return axios.post(this.validateSubscriptionUrl, subscription)
    },
    getStats(filter){
        return axios.post(this.getStatsUrl, filter)
    }
}