<template>
  <el-scrollbar class="custom-scrollable" >
    <el-menu
        :router="true"
        class="el-menu-vertical-demo d-flex flex-column justify-content-between h-100"
        :collapse="$store.state.sidebarCollapsed"
    >
      <el-menu-item index="1" :class="{ 'active-menu-item': $route.path === '/home' }" route="/home">
        <el-icon class="mb-3">
          <i class="bi bi-graph-up"></i>
        </el-icon>
        <span class="menu-title"> Dashboard</span>
      </el-menu-item>
      <el-menu-item index="2" :class="{ 'active-menu-item': $route.path === '/qrcodes' }" route="qrcodes">
        <el-icon class="mb-3">
          <i class="bi bi-qr-code-scan"></i>
        </el-icon>
        <span class="menu-title">Magic QR</span>
      </el-menu-item>
      <el-menu-item index="3" class="border-0" :class="{ 'active-menu-item': $route.path === '/magicurls' }" route="magicurls">
        <el-icon class="mb-3">
          <i class="bi bi-link"></i>
        </el-icon>
        <span class="menu-title">Magic URL</span>
      </el-menu-item>
      <el-menu-item index="4" class="border-0" :class="{ 'active-menu-item': $route.path === '/links' }" route="links">
        <el-icon class="mb-3">
          <i class="bi bi-arrow-clockwise"></i>
        </el-icon>
        <span class="menu-title">Link Rotator</span>
      </el-menu-item>
      <el-menu-item index="5" class="border-0" :class="{ 'active-menu-item': $route.path === '/biolinks' }" route="biolinks">
        <el-icon class="mb-3">
          <i class="bi bi-share"></i>
        </el-icon>
        <span class="menu-title">Bio Links</span>
      </el-menu-item>
      <el-menu-item :class="{ 'active-menu-item': $route.path === '/domains' }" class="border-0" index="6"
                    route="domains">
        <el-icon class="mb-3">
          <i class="bi bi-globe-americas"></i>
        </el-icon>
        <span class="menu-title">Domains</span>
      </el-menu-item>
      <el-menu-item index="7" class="border-0" :class="{ 'active-menu-item': $route.path === '/pixels' }"
                    route="pixels">
        <el-icon class="mb-3">
          <i class="bi bi-code-slash"></i>
        </el-icon>
        <span class="menu-title">Codes</span>
      </el-menu-item>
      <el-menu-item index="8" class="border-0" :class="{ 'active-menu-item': $route.path === '/designs' }"
                    route="designs">
        <el-icon class="mb-3">
          <i class="mgc_qrcode_line"></i>
        </el-icon>
        <span class="menu-title">Designs</span>
      </el-menu-item>
      <el-menu-item v-if="Role == 10 || Role == 20" index="9" class="border-0" :class="{ 'active-menu-item': $route.path === '/users' }"
                    route="users">
        <el-icon class="mb-3">
          <i class="bi bi-people"></i>
        </el-icon>
        <span class="menu-title">Users</span>
      </el-menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
export default {
  name: "SidebarComp",
  components: {},
  data(){
    return{
      Role:0,
    }
  },
  beforeMount() {
    this.Role = sessionStorage.getItem("Role");

  },
}
</script>

<style scoped>
.el-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px !important;
  width: 130px !important;
  font-size: 13px !important;
  border: none !important;
  font-weight: normal !important;
  margin-left: 5px;
}
.el-menu-item.is-active {
  color: green !important;
 
}

</style>