<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Pixels</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input v-model="filter.filterText" placeholder="search here..." @keyup="GetAll()" clearable class="me-1">
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <router-link to="add-pixel">
          <el-button @click="$store.state.pageId = 0" type="primary">Add Pixel</el-button>
        </router-link>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table :data="Pixels" :fit="true" max-height="80vh"
              class="cstm-table" header-cell-class-name="tbl-header" size="small" stripe
    >
      <el-table-column label="#" type="index" width="55"></el-table-column>
      <el-table-column label="Code Name" prop="codeName" min-width="250">
      </el-table-column>
      <el-table-column label="Type" prop="type" min-width="150"></el-table-column>
      <el-table-column label="Created At" prop="creationDate" min-width="150">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.creationDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="Action" width="120px">
        <template #default="scope">
            <el-tooltip content="Delete">
              <el-icon color="red" @click="takeData(scope.row,'del')">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-model="deleteDialog" title="Delete Pixel" width="450">
      <template #default>
        <span>Are you sure? You want to delete <span
            class="mfw-bold text-danger">{{ currentPixel.codeName }}</span> pixel.</span>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="deleteDialog = false, deletePixel()">Delete</el-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import pixelCodeService from "@/Services/pixelCodeService";
import moment from "moment";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "PixelsList",
  data() {
    return {
      loading: true,
      deleteDialog: false,
      currentPixel: {},
      Pixels: [],
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 30,
      },
    }
  },
  methods: {
    takeData(pixel, type) {
      this.currentPixel = {...pixel};
      if (type === "del") {
        this.deleteDialog = true;
      }
      if (type === 'edit') {
        this.$store.state.pageId = this.currentPixel.id;
        this.$router.push('add')
      }

    },
    async deletePixel() {
      try {
        this.loading = true;
        let response = await pixelCodeService.remove(this.currentPixel.id)

        if (response.data == true) {
          ShowMessage("success", "Pixel deleted successfully");
          this.GetAll();
        }
        this.loading = false;
      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    getFormattedDate(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    async GetAll() {
      try {
        this.loading = true;
        let response = await pixelCodeService.getAll(this.filter);

        this.Pixels = response.data.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

  },
  created() {
    this.GetAll()
  }
}
</script>
<style scoped>
.status-chip {
  width: 80px !important;
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>